.fee-dropdown {
  font-size: 14px;
  position: relative;

  .selector {
    min-width: 150px;
  }

  .content-border {
    z-index: 3;
    
    .dropdown-content {
      .prices {
        .price {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;
          line-height: 20px;

          padding: 8px 12px 8px 16px;

          &:hover {
            opacity: 0.8;
          }

          .label {
            margin-right: 8px;
            font-weight: 500;
          }

          .custom-input {
            text-align: right;
            height: 24px;
            max-width: 100px;
          }

          .unit {
            margin-left: 4px;
          }
        }
      }
    }
  }
}
