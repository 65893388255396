@import '../../styles/variables.scss';

.display-container {
  background-color: white;
  width: 100%;
  max-width: 26rem;
  padding: 1rem 2rem;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  &.success {
    .icon {
      border: 2px solid green;
    }
  }

  &.error {
    .icon {
      border: 2px solid red;
    }
  }

  .title {
    margin-top: 1rem;

    .icon {
      border-radius: 2rem;
    }

    h1 {
      margin: 0.25rem 0 0 1rem;
    }
  }

  .message {
    margin-top: 2rem;

    .transactions {
      display: flex;
      flex-direction: column;

      a {
        margin-top: 1rem;
      }
    }
  }

  button {
    margin-top: 1.5rem;
    cursor: pointer;
    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
}