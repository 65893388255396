$form-background: #f4f3f1;
$form-dark-background: #e5e5e5;
$form-grey-text: #9a9a9a;
$yellow: #f6b451;
$star-green: rgba(22, 197, 61, 1);
$washed-green: rgba(0, 159, 101, 0.05);
$dust-blue: #a9ecf5;
$washed-blue: rgba(33, 157, 255, 0.05);
$hover-20: rgba(0, 0, 0, 0.2);
$dark-grey: rgba(102, 102, 102, 1);
$medium-grey: rgba(153, 153, 153, 1);
$steel-grey: rgba(77, 77, 77, 1);
$preview-gold: rgba(243, 194, 99, 1);
$preview-gold-light: rgba(243, 194, 99, 0.1);
$fontFamily: "Inter", "Inter UI", -apple-system, BlinkMacSystemFont,
  "San Francisco", "Helvetica Neue", Arial, sans-serif;
