@import "../../styles/variables.scss";

.loading {
  &.has-text {
    background-color: rgb(81, 81, 81);
    padding: 2rem;
    border-radius: 24px;
  }

  .loading-text {
    color: white;
    font-weight: bold;
    text-align: center;
    margin-bottom: 24px;
  }

  .lds-ring {
    margin: 2rem auto;
    width: 5rem;
    height: 5rem;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 4rem;
    height: 4rem;
    margin: 0.5rem;
    border: 0.5rem solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
  }

  &.small {
    .lds-ring {
      width: 3rem;
      height: 3rem;
    }
    .lds-ring div {
      width: 2.5rem;
      height: 2.5rem;
      margin: 0.25rem;
    }
  }

  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
