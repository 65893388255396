@import url("https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@400;500;600;700;800&display=swap");
@import url("https://rsms.me/inter/inter.css");

@import "./styles/variables.scss";

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  scroll-behavior: smooth;
}

body {
  height: 100%;
}

body {
  position: relative;
  min-height: 100%;
  font-size: 16px;
  font-family: $fontFamily;
  margin: 0;
}

button {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
  border: 0;
  cursor: pointer;
  user-select: none;
}

p {
  line-height: 1.5;
}

.modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1000;
}

.logo-container {
  border-radius: 24px;
  background-color: black;
  height: 48px;
  width: 48px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 12px;

  svg {
    margin: 0 !important;
    path {
      fill: white;
    }
  }
}

form {
  background-color: $form-background;
  min-height: 18rem;
  border-radius: 1.5rem;

  .form-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;

    .back {
      width: 2rem;
      cursor: pointer;
      padding: 0.5rem;
    }

    .title {
      font-weight: bold;
    }

    .close {
      border: none;
      width: 2rem;
      cursor: pointer;
      text-align: right;
    }
  }

  input {
    font-family: "Inter", "Inter UI", -apple-system, BlinkMacSystemFont,
      "San Francisco", "Helvetica Neue", Arial, sans-serif;
  }

  .denomination {
    display: flex;
    flex-direction: column;

    .action {
      font-size: 16px;
      margin: 0rem 0rem 0.75rem;
    }

    .label {
      display: flex;
      flex-direction: row;
      align-items: center;
      min-width: 12rem;

      svg {
        border-radius: 8px;
        margin-right: 12px;
      }

      .value-balance {
        display: flex;
        flex-direction: column;
        .value {
          font-size: 20px;
          font-weight: 600;
        }
      }
    }
  }

  .amount {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 10px 12px;
    border: none;
    font-size: 16px;

    width: 16rem;
    height: 40px;
    left: 0px;
    top: 36px;

    background: white;
    border-radius: 8px;
    color: black;
    font-weight: bold;

    &.wstr {
      color: $dust-blue;
    }

    &.star {
      color: $star-green;
    }
  }
}

.form-holder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.toggle-exchange {
  padding: 0.5rem;
  position: absolute;
  border: 2px solid $form-dark-background;
  background-color: $form-background;
  border-radius: 999rem;
  width: 3em;
  height: 3em;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  bottom: -1.5em;
  &:hover {
    background-color: $form-dark-background;
  }
}

.swap-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  margin-top: 0;

  .half {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 2.25rem;
    width: 100%;
    border-radius: 1.5rem;
    min-height: 9rem;

    &.deposit {
      padding-bottom: 2rem;
      position: relative;
    }

    &.receive {
      background-color: $form-dark-background;
      padding-top: 3rem;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }

    @media (max-width: 599px) {
      padding: 1.75rem;
    }
  }
}

.assets {
  display: flex;
  flex-direction: column;
  margin-left: 3rem;

  @media screen and (max-width: 767px) {
    margin-left: 1rem;
  }

  @media (max-width: 599px) {
    margin: 1rem 0;
  }

  .exchange-rate {
    margin: 1rem 0rem;
    // color: $medium-grey;
    font-weight: 600;
  }
}

.receive-display {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 3rem;

  @media screen and (max-width: 767px) {
    margin-left: 1rem;
  }

  @media (max-width: 599px) {
    width: 100%;
    margin: 1rem 0;
  }

  .tx-cost {
    margin-top: 1rem;
  }

  .exchange-rate {
    margin: 0rem 0rem 1rem;
    color: $medium-grey;
    font-weight: 600;
  }

  .stars {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;

    background-color: $form-background;
    border-radius: 8px;
    padding: 12px;
    max-width: 16rem;
  }

  .wstr {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 10px 12px;

    max-width: 16rem;
    height: 40px;
    left: 0px;
    top: 36px;

    background: white;
    border-radius: 8px;
    color: $medium-grey;
  }
}

button.trade-button {
  margin-top: 1.5rem;
  width: 100%;
  border-radius: 1.5rem;
  font-size: 1.25rem;
  font-weight: 600;
  height: 5rem;
  border: none;
  color: white;
  background-color: black;

  // @media screen and (max-width: 767px) {
  //   width: 26rem;
  // }

  &.disabled {
    background-color: $form-dark-background;
    color: $medium-grey;
  }

  &:hover {
    opacity: 0.9;
    cursor: pointer;
  }
}

form.wallet-form {
  padding: 1rem;
  background-color: white;
  max-width: 30rem;

  .metamask-warning {
    margin-bottom: 2rem;
    padding: 0rem 0.5rem;
  }
}

.wallet-entry {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: $form-background;
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  margin-top: 0.5rem;
  border: 1px solid $form-dark-background;
  height: 2.5rem;

  &.selected {
    background-color: $form-dark-background;

    &:hover {
      cursor: auto;
    }
  }

  &:hover {
    cursor: pointer;
    background-color: $form-dark-background;
  }

  .checkmark {
    height: 1.5rem;
    width: 1.5rem;
  }

  .name {
    font-weight: bold;
  }

  img {
    width: 2rem;
  }
}

form.master-ticket-form {
  padding: 1rem;
  background-color: $form-background;
  display: flex;
  flex-direction: column;
  min-width: 400px;

  button.close {
    background-color: $form-background;
  }

  label {
    margin: 0.75rem 0rem 0.25rem 0.125rem;
    font-weight: 600;
  }

  input {
    font-size: 16px;
    border-radius: 0.25rem;
    padding: 0.5rem;
    background-color: white;
    border: 1px solid $form-dark-background;
  }

  .checkbox {
    margin: 0.8rem 0.5rem 0rem 0.5rem;
  }

  button.submit {
    color: black;
    background-color: rgba(0, 0, 0, 0.2);
    border: none;
    cursor: pointer;
    margin: 1rem 0rem 0.5rem;
    padding: 1.25rem;

    &:hover {
      background-color: rgba(0, 0, 0, 0.4);
      color: white;
    }

    &.disabled:hover {
      color: black;
      background-color: rgba(0, 0, 0, 0.2);
    }
  }
}

form.confirmation-form {
  width: 100%;
  padding: 2.25rem;
  background-color: $preview-gold-light;

  .swap-message,
  .receive-message {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: $dark-grey;
  }

  .receive-message {
    margin-top: 20px;
  }

  .label-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;

    &:nth-child(2) {
      margin-bottom: 16px;
    }

    .value {
      font-size: 64px;
      font-weight: 600;
      color: $preview-gold;
    }

    .logo-container {
      background-color: $preview-gold;
    }

    .label {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: 12px;

      .wstr,
      .star {
        line-height: 32px;
        font-weight: 600;
        font-size: 20px;
        color: $preview-gold;
      }
    }

    .stars {
      flex: 1 1 auto;
      margin-left: 12px;
      color: $dark-grey;
      width: 16rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      @media (max-width: 599px) {
        width: 100%;
        white-space: normal;
      }
    }
  }

  .info-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;

    .left {
      color: $dark-grey;
      font-weight: 600;
    }

    .right {
      color: $medium-grey;
    }
  }

  .buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 2rem;

    button {
      flex: 1 1 0%;
      // color: white;
      border: none;
      cursor: pointer;

      &:hover {
        opacity: 0.9;
      }

      &.cancel {
        background-color: $form-grey-text;
      }

      &.confirm {
        background-color: black;
        color: white;
        margin-left: 12px;
      }
    }
  }
}

.layout-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0 1rem;

  @media screen and (min-width: 1000px) {
    width: 960px;
    margin: 0 auto;
  }
}

.measure {
  max-width: 34em;
}

.measure-narrow {
  max-width: 20em;
}

.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.w-6\/12 {
  width: 50%;
}

.w-full {
  width: 100%;
}

.home-section-img {
  height: 22em;
  content: "";
  background-color: black;
  border-radius: 1.5em;
  @media screen and (max-width: 767px) {
    display: none;
  }
}

.layout {
  width: 100%;
  max-width: 60em;
  margin-top: 6em;

  @media screen and (max-width: 767px) {
    margin-top: 4em;
    width: 100%;
  }
}

.app-layout {
  width: 100%;
  max-width: 60em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 6em;
  padding-bottom: 2em;

  @media screen and (max-width: 767px) {
    padding-top: 3em;
    padding-bottom: 1em;
  }
}

h2 {
  //styleName: Sans/H2;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: -0.011em;
  text-align: left;
}

.main-title {
  font-size: 50px;
  font-weight: 700;
}

.secondary-title {
  font-size: 30px;
  font-weight: 700;
}

.hero-text {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: -0.03em;
}

h3,
.section-title {
  font-size: 20px;
  font-weight: 700;
}

p a {
  color: #999999;
  text-decoration: none;
}

a {
  text-decoration: none;
}

.pill-button {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 999px;
  padding: 0.5rem 0.75rem;
  color: black;
  text-decoration: none;
  line-height: 32px;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0em;
  border: none;
  cursor: pointer;
}

.toolbar-link {
  display: flex;
  height: 3em;
  align-items: center;
  padding-left: 1em;
  padding-right: 1em;
  color: black;
  text-decoration: none;
  //styleName: Sans/H3;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0em;
  border: none;
}

.bg-gray {
  background-color: #f2f2f2;
}

.bg-yellow {
  background-color: #f6b451;
}

.ml-0\.5em {
  margin-left: 0.5em;
}

.mr-0\.5em {
  margin-right: 0.5em;
}

.ml-1em {
  margin-left: 1em;
}

.mr-1em {
  margin-right: 1em;
}

.hero-rect {
  width: 100%;
  height: 540px;
  padding: 2em;
  position: relative;
  border-radius: 24px;
  background-size: cover;
  background-color: black;
}

.hero-rect .inner-text {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 2em;
  color: white;
}

.hero-rect .logo {
  position: absolute;
  top: 2em;
  left: 2em;
}

.hero-rect {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: -0.03em;
  text-align: left;
}

ul {
  margin: 0;
  padding: 0;
}

.bubbleLink {
  list-style: none;
}

.bubbleLink a {
  display: flex;
  align-items: center;
}

.bubbleLink .bubble {
  width: 4em;
  height: 4em;
  border-radius: 999px;
  margin-right: 0.75em;
  background-color: #999999;
}

.bubbleLink .textGroup {
  display: flex;
  flex-direction: column;
}

.mt-1 {
  margin-top: 1em;
}

.mt-2 {
  margin-top: 2em;
}

.mb-4em {
  margin-bottom: 4em;
}

.inline-block {
  display: inline-block;
}

.bubbleLink .textGroup .title {
  padding: 0;
  margin: 0;
  //styleName: Sans / UI + Control;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: black;
}

.bubbleLink .textGroup .caption {
  padding: 0;
  margin: 0;
  //styleName: Sans / UI + Control;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #999999;
}

.fourOFour {
  width: 100%;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.confirm-trade-modal {
  padding: 24px;
  width: 100%;
  max-width: 400px;
  background-color: white;
  border-radius: 24px;

  .message {
    margin-bottom: 24px;
  }

  .buttons {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  flex-direction: row;
  justify-content: space-between;

  .cancel,
  .confirm {
    width: 160px;
    border: none;
    color: white;
    cursor: pointer;
  }

  .cancel {
    background-color: $medium-grey;
  }
  .confirm {
    background-color: black;
  }
}

button:hover {
  opacity: 0.8;
}

.token-info table {
  border-collapse: separate;
  border-spacing: 0;
  // min-width: 350px;
  width: 100%;
}

.token-info table tr th,
.token-info table tr td {
  border-right-width: 2px;
  border-right-style: solid;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  padding: 8px !important;
  border-color: $form-dark-background;
}

.token-info table tr th:first-child,
.token-info table tr td:first-child {
  border-left-width: 2px;
  border-left-style: solid;
  border-color: $form-dark-background;
  width: 200px;
}

.token-info table tr:first-child td {
  border-top-width: 2px;
  border-top-style: solid;
  text-align: left;
  vertical-align: middle;
  border-top-color: $form-dark-background;
}

/* top-left border-radius */
.token-info table tr:first-child td:first-child {
  border-top-left-radius: 8px;
}

/* top-right border-radius */
.token-info table tr:first-child td:last-child {
  border-top-right-radius: 8px;
}

/* bottom-left border-radius */
.token-info table tr:last-child td:first-child {
  border-bottom-left-radius: 8px;
}

/* bottom-right border-radius */
.token-info table tr:last-child td:last-child {
  border-bottom-right-radius: 8px;
}

.button-sm {
  // display:flex;
  // align-items: center;
  // justify-content: center;
  padding-left: 1em;
  padding-right: 1em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  border-radius: 99em;
  font-weight: 500;
  &:hover {
    opacity: 0.6;
  }
}

.button-bg-black {
  background-color: black;
  color: white;
}

.justify-between {
  justify-content: space-between;
}

.header-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 2rem;
  margin-top: 3em;
  text-align: center;

  @media screen and (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
    padding: 0.5rem 1rem;
  }
}

.homeHeader {
  width: 100%;

  @media (max-width: 899px) {
    max-width: 576px;
  }

  .innerCol {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 1rem;
    margin-top: 5.5em;

    @media screen and (max-width: 767px) {
      margin-top: 3em;
    }
  }

  // .treasury {
  //   background-color: $yellow;
  //   text-align: center;
  //   cursor: pointer;

  //   .modal {
  //     cursor: default;

  //     .message-container {
  //       display: flex;
  //       flex-direction: column;
  //       // justify-content: space-between;
  //       align-items: flex-start;
  //       padding: 36px;
  //       position: absolute;
  //       width: 400px;
  //       height: 320px;
  //       left: 35px;
  //       top: 86px;
  //       background: #F3C263;
  //       border-radius: 24px;
  //       text-align: left;

  //       .title {
  //         font-size: 16px;
  //         font-weight: 600;
  //         line-height: 24px;
  //         color: $steel-grey;
  //         margin-bottom: 24px;
  //       }

  //       .message, .stars {
  //         font-size: 24px;
  //         font-weight: 600;
  //         line-height: 32px;
  //         color: $steel-grey;

  //         .stars {
  //           color: black;
  //         }
  //       }
  //     }
  //   }
  // }

  .walletSection {
    margin-left: 3rem;

    @media (max-width: 899px) {
      margin-left: 0;
    }

    @media (max-width: 599px) {
      width: 100%;
    }
  }

  nav {
    display: flex;
    // align-items: center;
  }
}

.timer {
  padding-left: 1em;
  padding-right: 1em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  border-radius: 99em;
  font-weight: 500;
  // width: 284px;
  display: table;
}

.timer p b {
  font-weight: 600;
  // font-family: 'Source Code Pro';
}

@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}

@-webkit-keyframes blink-animation {
  to {
    visibility: hidden;
  }
}

.text-black {
  color: black;
}

.table {
  display: table;
}

.ml-0\.25em {
  margin-left: 0.25em;
}

.button-lg {
  width: 100%;
  border-radius: 1.5rem;
  font-size: 1.25rem;
  font-weight: 600;
  height: 5rem;
  border: none;
}

.text-gray {
  color: rgba(0, 0, 0, 0.6);
}

.message-container {
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  // align-items: flex-start;
  padding: 24px;
  // position: absolute;
  width: 400px;
  // height: 320px;
  // left: 35px;
  // top: 86px;
  background: #f3c263;
  border-radius: 24px;
  text-align: left;
}

@media screen and (max-width: 767px) {
  .message-container {
    max-width: 250px;
  }
}

.relative {
  position: relative;
}

.light-dismiss {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.2);
  content: "";
  z-index: 100;
}

.bg-white {
  background-color: white;
}

.dropdown-button {
  color: $medium-grey;
  background-color: #fce7c0;
  border-radius: 8px;
  padding: 0.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
}

.gas-container {
  align-items: center;
}

.text-lightGray {
  color: $medium-grey;
}

.dropdown-menu {
  background-color: #fce7c0;
  border-radius: 16px;
  padding: 0.5em;
}

.dropdown-item {
  padding: 0.5em;
  margin-bottom: 0.25em;
  border-radius: 8px;
  display: flex;
  cursor: pointer;
}

.dropdown-input-container {
  padding-left: 0.5em;
  padding-right: 0.5rem;
  display: flex;
  align-items: center;
}

.dropdown-input-container input {
  width: 100px;
  margin-right: 8px;
  border-radius: 8px;
  border: none;
}

.dropdown-item:hover {
  background-color: #fef9f0;
}

p.label {
  margin-right: 8px;
  font-weight: 600;
}

.dropdown-item p {
  line-height: 16px;
  margin-top: 0;
  padding-top: 0;
  margin-bottom: 0;
  padding-bottom: 0;
}

.bug-report {
  align-self: flex-start;
  color: gray;
  text-decoration: underline;
  margin-top: 2rem;
}

.mainHeader {
  width: 100%;

  @media (max-width: 968px) {
    .responsive-hide {
      display: none;
    }
  }
}

.gradient-background {
  background: linear-gradient(
      180deg,
      rgba(25, 24, 49, 0.1) 2%,
      rgba(73, 79, 140, 0.6) 70%
    ),
    url("/components/Icons/hero.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.star-card {
  position: relative;
  // background-color: #f2f8f9;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 30px;
  margin: 5px;
  width: 100%;
  max-width: 300px;
  box-shadow: 0px 0px 10px rgba(196, 196, 196, 0.4);
}

// .star-card:hover {
//   transition: all 0.3s ease-out;
//   box-shadow: 0px 0px 10px rgba(135, 131, 135, 0.4);
//   top: -4px;
// }

.star-card .flex-container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 20px;
  border-width: 0;
  overflow: hidden;
}

@media (max-width: 990px) {
  .star-card .flex-container {
    width: 130px;
    align-items: flex-start;
    margin-top: -20px;
  }
}

@media (max-width: 990px) {
  .star-card {
    width: 130px;
    align-items: flex-start;
  }
}

.remove-when-reduced {
  display: flex;
  align-items: center;
}

@media (max-width: 867px) {
  .remove-when-reduced {
    display: none;
  }
}

.remove-when-reduced-2 {
  display: flex;
  align-items: center;
}

@media (max-width: 495px) {
  .remove-when-reduced-2 {
    display: none;
  }
}

.star-card .text-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: black;
  font-size: 12px;
  display: flex;
  flex: 1;
  margin-left: 10px;
  font-family: "Source Code Pro";
  font-weight: 600;
}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  overflow: hidden;
  max-width: 100%;
  background: none;
  border-radius: 10px;
}

.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.img-container {
  position: relative;
  display: inline-block;
  border-radius: 25px;
  overflow: hidden;
}

.img-container img {
  display: block;
  width: 100%;
  height: auto;
}

.img-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  mix-blend-mode: darken;
}

.social-container {
  border-radius: 50%;
  overflow: "hidden";
  width: 30px;
  height: 30px;
  justify-content: "center";
  align-items: "center";
  display: "flex";
  transition: transform 0.3s ease-in-out;
}

.social-container img {
  width: 100%;
  height: 100%;
}

.social-container:hover {
  transform: scale(1.1);
}
.responsive-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.market-card {
  max-width: 300px;
  margin-top: 15px;
}

@media (max-width: 1020px) {
  .responsive-box {
    flex-direction: column;
    align-items: center;
  }
  .responsive-box .market-card {
    width: 100%;
    max-width: 100%;
  }
}

.section-layout {
  width: 100%;
  max-width: 60em;
  margin-top: 6em;
  padding: 30px;
  border-radius: 20px;
  color: "white";
  justify-content: "center";
}

.section-layout-flex {
  width: 100%;
  max-width: 60em;
  margin-top: 6em;
  // padding: 30px;
  display: flex;
  flex-direction: row;
  color: "white";
  justify-content: "center";
  align-items: center;
}

@media (max-width: 767px) {
  .section-layout-flex {
    flex-direction: column;
  }
}

.custom-link {
  margin-left: 3;
  color: rgba(5, 176, 113, 1);
  font-weight: 600;
}

.custom-link:hover {
  color: rgba(5, 176, 113, 0.5);
  text-decoration: underline;
}

.text-content {
  font-size: 18px;
  font-weight: 400;
  color: #24221e;
}

.header-row {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
}

.logo-home {
  margin-left: 10px;
  flex: 1;
  font-size: 18px;
  font-weight: 600;
}

.section-item {
  font-size: 16px;
  color: #5a564f;
  font-weight: 600;
}

.card-container {
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: row;
}

.metrics-label {
  margin-right: 10px;
  font-weight: 600;
  font-family: Inter;
}

.button-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 25px;
}
