@import "../../styles//variables.scss";

.footer {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 8em;
  padding-bottom: 0em;
}

.footer .innerCol {
  width: 100%;
  display: flex;
  justify-content: space-between;
  max-width: 60em;

  @media screen and (max-width: 767px) {
    margin-top: 3em;
    width: 100%;
  }
}

.footer nav {
  display: flex;

  @media screen and (max-width: 767px) {
    flex-direction: column;
    justify-content: flex-start;
  }
}

.footer-home {
  padding-bottom: 12em;
  margin-top: 10em;
  color: black;
  background-color: #f3f2f0;
}
