@import '../../styles/variables.scss';

.walletSection {
  .wallet-display {
    padding: 0;
  
    .eth-balance {
      color: $medium-grey;
    }
  
    .wstr-balance {
      color: $dark-grey;
    }
  
    .address-refresh {
      align-items: center;
      height: 48px;
      padding-left: 1em;
      padding-right: 0.25em;
      // padding: 0.75rem 0.75rem 0.75rem 1.5rem;
      font-weight: 600;
      border-top-right-radius: 999px;
      border-bottom-right-radius: 999px;
      background-color: $form-dark-background;
      
      &:hover {
        background-color: $hover-20;
      }
  
      .refresh {
        width: 2em;
        height: 2em;
        border-radius: 999px;
        padding:0.5em;
        margin-left: .5rem;
        &:hover {
          background-color: $hover-20;
        }
      }
    }
  
    &.error {
      background-color: rgba(168, 27, 27, 1);
      padding: 0.5rem 2rem;
  
      .error-icon {
        color: white;
      }
  
      .error-message {
        color: white;
        font-size: 1rem;
        margin-left: 1rem;
      }
    }

    @media (max-width: 599px) {
      flex-direction: column-reverse;
      align-items: start;
      width: 100%;
      font-size: 14px;
      padding: 1rem;
      border-radius: 24px;

      .address-refresh {
        padding: .5rem .75rem;
        margin-bottom: .5rem;
        border-radius: 999px;
      }
    }
  }

  .connect-wallet {
    padding: .5rem 1.5rem;
    background-color: black;
    color: white;
  }
}